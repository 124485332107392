<template>
  <div class="h-full">
    <div class="p-6 space-y-2">
      <div class="flex gap-2 items-center">
        <ic-flag
          :size="20"
          class="text-bb-brand-purple"
        />
        <h4 class="h4">Whitelist</h4>
      </div>
      <p>
        Add partners/affiliates who are authorized to use your brand in ads and should be excluded from infringement
        lists.
      </p>
    </div>

    <div>
      <whitelist-table
        :whitelist="whitelist"
        :is-adding-infringement="isAddingInfringement"
        :touched-whitelist-url="touchedWhitelistUrl"
        @add-whitelist="$emit('add-whitelist', $event)"
        @remove-whitelist="$emit('remove-whitelist', $event)"
        @edit-whitelist="$emit('edit-whitelist', $event)"
      />
    </div>

    <div class="w-full p-6 flex justify-between items-center absolute bottom-0">
      <merge-button-round
        button-type="secondary"
        class="w-40"
        @click="$emit('close')"
      >
        <p>Cancel</p>
      </merge-button-round>
      <merge-button-round
        button-type="primary"
        class="w-40"
        @click="$emit('close')"
      >
        <p>Done</p>
      </merge-button-round>
    </div>
  </div>
</template>

<script>
import IcFlag from '@/components/icon/brightbid/ic-flag.vue'
import WhitelistTable from '@/views/site/search/competitor_monitoring/infringements_detector/whitelist/WhitelistTable.vue'

export default {
  name: 'WhitelistModal',
  components: {
    IcFlag,
    WhitelistTable,
  },
  props: {
    whitelist: {
      type: Array,
      required: true,
    },
    isAddingInfringement: {
      type: Boolean,
      default: false,
    },
    touchedWhitelistUrl: {
      type: Object,
      default: () => {},
    },
  },
}
</script>

<style scoped lang="scss"></style>
