<template>
  <div class="flex items-center justify-between gap-8">
    <div
      v-for="item in summary"
      :key="item.value"
      class="w-full space-y-2"
    >
      <h5 class="h5">{{ item.title }}</h5>
      <div
        class="border-l-8 h-18 rounded-md px-4 overflow-hidden bg-white"
        :class="item.borderColor"
      >
        <div class="flex justify-between items-center h-full">
          <div class="flex items-center gap-4 h-full">
            <div
              class="w-9 h-9 rounded-full flex items-center justify-center"
              :class="[item.backgroundColor, item.textColor]"
            >
              <component :is="item.icon" />
            </div>

            <h4 class="h2">{{ infringementSummary[item.value] || '0' }}</h4>
          </div>

          <h5
            v-if="item.value === 'whiteListCount'"
            class="h5 text-bb-brand-purple cursor-pointer"
            @click="$emit('open-whitelist-modal')"
          >
            Check list
          </h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IcWarning from '@/components/icon/brightbid/ic-warning.vue'
import IcLocation from '@/components/icon/brightbid/ic-location.vue'
import IcFlag from '@/components/icon/brightbid/ic-flag.vue'

export default {
  name: 'InfringementSummary',
  components: {
    IcWarning,
    IcLocation,
    IcFlag,
  },
  props: {
    infringementSummary: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      summary: [
        {
          title: 'New Infringements',
          value: 'newCount',
          textColor: 'text-bb-red-error',
          backgroundColor: 'bg-bb-decorative-red',
          borderColor: 'border-bb-red-error',
          icon: 'ic-warning',
        },
        {
          title: 'All Infringements',
          value: 'allCount',
          textColor: 'text-bb-yellow-500',
          backgroundColor: 'bg-bb-yellow-50',
          borderColor: 'border-bb-yellow-500',
          icon: 'ic-location',
        },
        {
          title: 'Whitelist Infringements',
          value: 'whiteListCount',
          textColor: 'text-bb-brand-purple',
          backgroundColor: 'bg-bb-secondary-purple',
          borderColor: 'border-bb-brand-purple',
          icon: 'ic-flag',
        },
      ],
    }
  },
}
</script>

<style scoped lang="scss"></style>
