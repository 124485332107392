var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full px-6"},[_c('div',{staticClass:"border rounded-lg overflow-hidden"},[_c('div',{staticClass:"max-h-94 overflow-y-auto base-scrollbar"},[_c('table',{staticClass:"w-full"},[_c('table-header',{staticClass:"sticky top-0 bg-white z-10",attrs:{"headers":_vm.headers,"sorting-order":_vm.sortingOrder},on:{"sort-table":_vm.sortTable}}),_c('tbody',[(_vm.whitelist.length === 0)?_c('tr',[_vm._m(0)]):_vm._l((_vm.sortedTableData),function(item,index){return _c('tr',{key:item.id,staticClass:"whitespace-no-wrap h-11 font-normal w-full text-sm",class:[
              _vm.hoveredRow === index && !_vm.touchedWhitelistUrl[item.id] ? 'bg-bb-background-purple-0' : 'bg-white',
              index % 2 === 0 ? 'bg-white' : 'bg-neutral-0',
              _vm.touchedWhitelistUrl[item.id] && _vm.touchedWhitelistUrl[item.id]?.action === 'remove' ? 'bg-red-100' : '',
              _vm.touchedWhitelistUrl[item.id] && _vm.touchedWhitelistUrl[item.id]?.action === 'edit'
                ? 'bg-bb-purple-10'
                : '',
            ],on:{"mouseenter":function($event){_vm.hoveredRow = index},"mouseleave":function($event){_vm.hoveredRow = null}}},[_c('td',{staticClass:"px-4 py-2 whitespace-no-wrap sticky left-0",class:[
                _vm.hoveredRow === index && !_vm.touchedWhitelistUrl[item.id] ? 'bg-bb-background-purple-0' : 'bg-white',
                index % 2 === 0 ? 'bg-white' : 'bg-neutral-0',
                _vm.touchedWhitelistUrl[item.id] && _vm.touchedWhitelistUrl[item.id]?.action === 'remove' ? 'bg-red-100' : '',
                _vm.touchedWhitelistUrl[item.id] && _vm.touchedWhitelistUrl[item.id]?.action === 'edit'
                  ? 'bg-bb-purple-10'
                  : '',
              ]},[(_vm.selectedWhitelist?.id !== item.id)?_c('p',[_vm._v(_vm._s(_vm.touchedWhitelistUrl[item.id]?.url ?? item.url))]):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedWhitelist.url),expression:"selectedWhitelist.url"}],staticClass:"bg-transparent w-full border-2 border-bb-brand-purple rounded-md px-2 focus:outline-none",domProps:{"value":(_vm.selectedWhitelist.url)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.selectedWhitelist, "url", $event.target.value)},function($event){_vm.invalidWhitelistUpdate = false}]}})]),_c('td',{staticClass:"w-50"},[_c('div',{staticClass:"flex gap-6 justify-end items-center pr-6"},[(_vm.invalidWhitelistUpdate && _vm.selectedWhitelist?.id === item.id)?_c('p',{staticClass:"text-bb-red-error text-xs"},[_vm._v(" URL already exists ")]):_vm._e(),(_vm.touchedWhitelistUrl[item.id]?.action === 'edit' && _vm.touchedWhitelistUrl[item.id])?_c('ellipsis-loader',{staticClass:"text-bb-brand-purple"}):_vm._e(),(_vm.selectedWhitelist?.id === item.id && _vm.touchedWhitelistUrl[item.id]?.action !== 'edit')?_c('ic-check',{staticClass:"cursor-pointer text-success",attrs:{"size":18},on:{"click":_vm.editWhitelist}}):_vm._e(),(_vm.selectedWhitelist?.id === item.id && _vm.touchedWhitelistUrl[item.id]?.action !== 'edit')?_c('ic-cross',{staticClass:"cursor-pointer text-bb-red-error hover:text-bb-red-error",attrs:{"size":20},on:{"close":function($event){_vm.selectedWhitelist = null
                    _vm.invalidWhitelistUpdate = false}}}):_vm._e(),(_vm.selectedWhitelist?.id !== item.id && _vm.hoveredRow === index && !_vm.touchedWhitelistUrl[item.id])?_c('ic-edit',{class:_vm.touchedWhitelistUrl[item.id]
                      ? ' pointer-events-none'
                      : 'text-bb-disabled-buttons hover:text-bb-brand-purple cursor-pointer',attrs:{"size":20},on:{"click":function($event){return _vm.selectWhitelist(item)}}}):_vm._e(),_c('ic-negative',{staticClass:"cursor-pointer",class:_vm.touchedWhitelistUrl[item.id]
                      ? 'text-bb-disabled-buttons pointer-events-none'
                      : 'text-bb-red-error',attrs:{"size":20},on:{"click":function($event){return _vm.removeWhitelist(item)}}})],1)])])}),_c('tr',{staticClass:"bg-neutral-50 h-14 sticky bottom-0"},[_c('td',{attrs:{"colspan":"4"}},[_c('div',{staticClass:"flex justify-between items-center pl-6 pr-2"},[_c('div',{staticClass:"flex items-center gap-6 w-full"},[_c('ic-plus',{staticClass:"text-bb-brand-purple cursor-pointer",attrs:{"size":24},on:{"click":_vm.addWhitelist}}),(!_vm.isAddingInfringement)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.whitelistInput),expression:"whitelistInput"}],staticClass:"bg-transparent w-full border-0 focus:outline-none custom-placeholder font-medium",attrs:{"type":"text","placeholder":"Type URL ..."},domProps:{"value":(_vm.whitelistInput)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.addWhitelist.apply(null, arguments)},"input":[function($event){if($event.target.composing)return;_vm.whitelistInput=$event.target.value},function($event){_vm.hasDuplicateWhitelist = false
                      _vm.invalidWhitelist = false}]}}):_vm._e(),(_vm.isAddingInfringement)?_c('ellipsis-loader',{staticClass:"text-bb-brand-purple"}):_vm._e()],1),_c('div',[(!_vm.hasDuplicateWhitelist && !_vm.invalidWhitelist)?_c('p',{staticClass:"text-bb-neutral-gray text-xs cursor-default w-32"},[_vm._v(" Press Enter to save ")]):_vm._e(),(_vm.hasDuplicateWhitelist)?_c('p',{staticClass:"text-bb-red-error text-xs cursor-default w-48"},[_vm._v(" This whitelist already exists! ")]):_vm._e(),(_vm.invalidWhitelist)?_c('p',{staticClass:"text-bb-red-error text-xs cursor-default w-32"},[_vm._v(" Please input a valid url. ")]):_vm._e()])])])])],2)],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('td',{attrs:{"colspan":"4"}},[_c('div',{staticClass:"mx-auto my-20 flex flex-col items-center justify-center"},[_c('p',{staticClass:"text-xl my-2"},[_vm._v("No whitelisted URLs found...")])])])
}]

export { render, staticRenderFns }