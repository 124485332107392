<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    @click="$emit('click')"
  >
    <path
      d="M10.0013 2.56152C5.4013 2.56152 1.66797 6.29486 1.66797 10.8949C1.66797 15.4949 5.4013 19.2282 10.0013 19.2282C14.6013 19.2282 18.3346 15.4949 18.3346 10.8949C18.3346 6.29486 14.6013 2.56152 10.0013 2.56152ZM10.0013 17.5615C6.3263 17.5615 3.33464 14.5699 3.33464 10.8949C3.33464 7.21986 6.3263 4.22819 10.0013 4.22819C13.6763 4.22819 16.668 7.21986 16.668 10.8949C16.668 14.5699 13.6763 17.5615 10.0013 17.5615ZM5.83464 10.0615H14.168V11.7282H5.83464V10.0615Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IcNegative',
  props: {
    size: {
      type: [String, Number],
      default: 16,
    },
  },
}
</script>

<style scoped lang="scss"></style>
