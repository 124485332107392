<template>
  <div class="flex gap-2 items-start p-6 pb-20 bg-neutral-0">
    <div>
      <ic-flag class="text-bb-brand-purple" />
    </div>
    <div>
      <h4 class="h4">Whitelist</h4>
      <p class="text-bb-text-default font-normal">
        Exclude partners/affiliates that are permitted to use the brand in advertisements from infringement detection.
      </p>
      <p
        class="text-bb-brand-purple font-medium cursor-pointer"
        @click="$emit('open-whitelist-modal')"
      >
        Check List
      </p>
    </div>
  </div>
</template>

<script>
import IcFlag from '@/components/icon/brightbid/ic-flag.vue'

export default {
  name: 'WhitelistSection',
  components: { IcFlag },
}
</script>

<style scoped lang="scss"></style>
