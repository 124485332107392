<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.4986 11C21.0386 6.83 17.7286 3.52 13.5586 3.06V1H11.5586V3.06C7.38859 3.52 4.07859 6.83 3.61859 11H1.55859V13H3.61859C4.07859 17.17 7.38859 20.48 11.5586 20.94V23H13.5586V20.94C17.7286 20.48 21.0386 17.17 21.4986 13H23.5586V11H21.4986ZM12.5586 19C8.68859 19 5.55859 15.87 5.55859 12C5.55859 8.13 8.68859 5 12.5586 5C16.4286 5 19.5586 8.13 19.5586 12C19.5586 15.87 16.4286 19 12.5586 19Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IcLocation',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>

<style scoped lang="scss"></style>
