<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.8971 2.1665H2.89714C1.98047 2.1665 1.23047 2.9165 1.23047 3.83317V13.8332C1.23047 14.7498 1.98047 15.4998 2.89714 15.4998H8.73047L7.0638 17.9998V18.8332H13.7305V17.9998L12.0638 15.4998H17.8971C18.8138 15.4998 19.5638 14.7498 19.5638 13.8332V3.83317C19.5638 2.9165 18.8138 2.1665 17.8971 2.1665ZM17.8971 12.1665H2.89714V3.83317H17.8971V12.1665Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IcDesktop',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>

<style scoped lang="scss"></style>
