<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    @click="$emit('click')"
  >
    <path
      d="M8.35588 16.6234L3.61456 11.648L2 13.3303L8.35588 20L22 5.68233L20.3968 4L8.35588 16.6234Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
export default {
  name: 'IcCheck',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>
